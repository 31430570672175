import Vue from "vue";
import store from "@/store";

export const setColorTheme = async selectedColor => {
  const { color, } = await store.state.system.configs.THEME;
  const CSSNode = document.createElement("style");
  CSSNode.innerHTML = `
    :root {
      --aff-primary: ${selectedColor || color.primary};
    }
  `;
  document.querySelector("head").appendChild(CSSNode);
};

export const setHeader = header => {
  Vue.component(header, () => import(`@/components/home/headers/${header}`));
  store.commit("system/SET_HEADER", header);
};